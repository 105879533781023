<template>
  <div>
      <!-- Table Container Card -->
      <b-card
          no-body
          class="mb-0"
      >

        <b-tabs class="mx-2 my-1">

          <b-tab active>
            <template #title>
              <span class="d-inline">نظرات محصولات</span>
            </template>
            <ProductComments/>
          </b-tab>

          <b-tab>
            <template #title>
              <span class="d-inline">نظرات آکادمی</span>
            </template>
            <AcademyComments/>
          </b-tab>

<!--          <b-tab>-->
<!--            <template #title>-->
<!--              <span class="d-inline">نظرات مقالات</span>-->
<!--            </template>-->
<!--            <BlogsComments/>-->
<!--          </b-tab>-->

          <b-tab>
            <template #title>
              <span class="d-inline">نظرات خدمات</span>
            </template>
            <ServicesComments/>
          </b-tab>

          <b-tab>
            <template #title>
              <span class="d-inline">نظرات فروشگاه ها</span>
            </template>
            <VendorComments/>
          </b-tab>
          <b-tab>
            <template #title>
              <span class="d-inline">نظرات بلاگ ها</span>
            </template>
            <BlogsComments/>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BTab,BTabs,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay,BModal,BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Helper from "@/libs/Helper";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {CommentDeleteByRequest, CommentGetAllRequest, CommentShowCommentsByRequest} from "@/libs/Api/Comments";
import VendorComments from "@/views/pages/comments/VendorComments";
import ProductComments from "@/views/pages/comments/ProductComments";
import AcademyComments from "@/views/pages/comments/AcademyComments";
import ServicesComments from "@/views/pages/comments/ServicesComments";
import BlogsComments from "@/views/pages/comments/BlogsComments";

export default {
  name: "CommentsList",
  title:"لیست نظرات",
  data(){
    return{

    }
  },
  methods:{

  },
  components: {
    BlogsComments,
    ServicesComments,
    AcademyComments,
    ProductComments,
    VendorComments,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,
    BFormCheckbox,
    BTab,
    BTabs,
    vSelect,
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
